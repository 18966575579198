<template>
  <header>
    <!-- header content begin -->
    <div class="uk-section uk-padding-remove-vertical">
      <nav
        class="uk-navbar-container uk-navbar-dark uk-sticky"
        data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top;"
        style=""
      >
        <div class="uk-container uk-navbar" data-uk-navbar="">
          <div class="uk-navbar-left">
            <div class="uk-navbar-item">
              <!-- logo begin -->
              <router-link class="uk-logo" :to="{ name: 'affiliate.home' }">
                <img
                  src="/logo.png"
                  data-src="/logo.png"
                  alt="logo"
                  width="146"
                  height="39"
                  data-uk-img=""
                />
              </router-link>
              <!-- logo end -->
              <!-- navigation begin -->
              <ul class="uk-navbar-nav uk-visible@m">
                <li>
                  <router-link :to="{ name: 'affiliate.home' }">
                    Home
                  </router-link>
                </li>
              </ul>
              <!-- navigation end -->
              <div class="uk-navbar-item in-mobile-nav uk-hidden@m">
                <a
                  class="uk-button"
                  href="#modal-full"
                  data-uk-toggle=""
                  aria-expanded="false"
                  ><i class="fas fa-bars"></i
                ></a>
                <div
                  id="modal-full"
                  class="uk-modal-full uk-modal"
                  data-uk-modal=""
                >
                  <div
                    class="
                      uk-modal-dialog uk-flex uk-flex-center uk-flex-middle
                    "
                    data-uk-height-viewport=""
                  >
                    <a class="uk-modal-close-full uk-button"
                      ><i class="fas fa-times"></i
                    ></a>
                    <div class="uk-width-large uk-padding-large">
                      <ul
                        class="uk-nav-default uk-nav-parent-icon uk-nav"
                        data-uk-nav=""
                      >
                        <li>
                          <router-link :to="{ name: 'affiliate.home' }">
                            Home
                          </router-link>
                        </li>
                      </ul>
                      <router-link :to="{ name: 'trader.my-account' }">My Account</router-link>
                      <a href="#" @click.prevent="logout">Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-navbar-right">
            <div class="uk-navbar-item uk-visible@m in-optional-nav">
              <router-link
                :to="{ name: 'login' }"
                class="uk-button uk-button-text"
                >{{ first_name }}<i
                  class="fas fa-chevron-down uk-margin-small-left"
                ></i
              ></router-link>
              <div
                class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left"
                style="left: 417.422px; top: 81px"
              >
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li><router-link :to="{ name: 'affiliate.my-account' }">My Account</router-link></li>
                  <li class="uk-active"><a href="#" @click.prevent="logout">Logout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        class="uk-sticky-placeholder"
        style="height: 92px; margin: 0px"
        hidden=""
      ></div>
    </div>
    <!-- header content end -->
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
  },

  computed: {
    first_name() {
      return this.$store.getters.getAuthUser.name.split(" ")[0];
    },
  },
};
</script>
